<template>
  <el-drawer
    title=""
    :visible.sync="onDrawerVisible"
    :with-header="false"
    @closed="onCloseDrawer"
    :size="size"
  >
    <slot></slot>
  </el-drawer>
</template>

<script>
export default {
  name: "WrapperDrawer",
  data() {
    return {
      onDrawerVisible: this.onVisible
    };
  },
  watch: {
    onVisible(v) {
      this.onDrawerVisible = v;
    }
  },
  methods: {
    onCloseDrawer() {
      this.$emit("onCloseDrawer");
    }
  },
  props: {
    onVisible: {
      default: false,
      type: Boolean
    },
    size: {
      default: "40%",
      type: String
    }
  }
};
</script>

<style lang="scss" scoped></style>
