<template>
  <section class="wrapper__business-earnings-list p-4">
    <h4 class="h4 font-semi-bold d-inline-block">Ingresos o Cobros</h4>
    <h4 class="h4 font-bold float-right text-right" v-if="showTotalEarnings">
      <small class="text-color-black d-block">Total ingresado</small>
      <FormatAmount :amount="totalEarnings" />
    </h4>

    <section class="input-filters my-3">
      <InputSearchComponent @onChangeSearch="onChangeSearch" />
      <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
      <FilterBetweenDatesSelect
        v-if="!setEarnings"
        @onAsyncDataForDates="onAsyncDataForDates"
      />
    </section>

    <LoadingSkeletonTable v-if="toggleApiRequest" />

    <template v-else>
      <template v-if="earnings.length">
        <paginate
          class="wrapper-pagination"
          name="resultQueryEarnings"
          :list="resultQueryEarnings"
          :per="perPaginate"
          :key="searchQuery"
        >
          <el-table
            :data="paginated('resultQueryEarnings')"
            stripe
            @row-click="onClickEarningView"
          >
            <el-table-column prop="description" label="Descripción" width="250">
            </el-table-column>
            <el-table-column label="Ingresado">
              <template slot-scope="scope">
                <FormatAmount :amount="scope.row.amount" />
              </template>
            </el-table-column>
            <el-table-column label="Ingreso neto">
              <template slot-scope="scope">
                <FormatAmount :amount="scope.row.net_income" />
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="Fecha"> </el-table-column>
          </el-table>
        </paginate>
        <paginate-links
          v-show="resultQueryEarnings.length > perPaginate"
          for="resultQueryEarnings"
          :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
          :simple="{
            next: 'Siguiente »',
            prev: '« Regresar'
          }"
        ></paginate-links>
      </template>
      <EmptyData v-else />
    </template>
    <WrapperDrawer
      :onVisible.sync="drawerEarningView"
      @onCloseDrawer="onCloseDrawerEarningView"
    >
      <section class="wrapper__business-earning-view p-4" v-if="earningView">
        <b-row>
          <b-col xs="12" lg="12">
            <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
              Información General
            </h4>

            <b-row class="my-4">
              <b-col xs="12" sm="12" md="12" lg="12">
                <b-row>
                  <b-col xs="12" lg="12">
                    <label for="" class="font-bold">Descripción</label>
                    <p class="">
                      {{ earningView.description }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" lg="12">
                    <label for="" class="font-bold">Fecha ingreso</label>
                    <p class="">{{ earningView.created_at }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" lg="12">
                    <label for="" class="font-bold">Valor ingresado</label>
                    <p>
                      <FormatAmount :amount="earningView.amount" />
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" lg="12">
                    <label for="" class="font-bold">Valor neto</label>
                    <p class="text-color-secondary font-bold">
                      <FormatAmount :amount="earningView.net_income" />
                    </p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col xs="12" lg="12">
                    <p>
                      <span class="text-color-primary font-bold d-block"
                        >Comisión Mydessk
                      </span>
                      <span class="text-color-danger"
                        >${{ earningView.commission_md }}</span
                      >
                    </p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col xs="12" lg="12">
                    <p>
                      <span class="text-color-primary font-bold d-block">
                        Comisión Bancaria
                        <ButtonText
                          text="Ver detalles"
                          class="m-0 p-0 ml-3 text-color-black"
                          @onClick="onViewCommissionBank"
                          v-if="false"
                        />
                      </span>
                      <span class="text-color-danger"
                        >${{ earningView.commission_bank_all }}</span
                      >
                    </p>
                  </b-col>
                </b-row>

                <div class="bg-color-gray p-3" v-if="showCommissionBank">
                  <b-row>
                    <b-col xs="12" lg="12">
                      <p class="font-bold text-color-secondary">
                        Detalles comisión bancaria
                      </p>
                      <p class="mt-2">
                        <span class="text-color-black"
                          >Comisión Datafast:
                        </span>
                        <span class="">${{ earningView.commission_df }}</span>
                      </p>
                      <p>
                        <span class="text-color-black"
                          >Cargo tarjeta crédito (4.5%):
                        </span>
                        <span class=""
                          >${{ earningView.commission_card_fee }}</span
                        >
                      </p>
                      <p>
                        <span class="text-color-black"
                          >Retención IVA (70%):
                        </span>
                        <span class=""
                          >${{ earningView.commission_withholding_iva }}</span
                        >
                      </p>
                      <p>
                        <span class="text-color-black"
                          >Retención Impusto a la Renta (2%):
                        </span>
                        <span class=""
                          >${{ earningView.commission_withholding_tax }}</span
                        >
                      </p>
                      <p>
                        <span class="text-color-black"
                          >IVA Cobro Servicio:
                        </span>
                        <span class=""
                          >${{ earningView.commission_iva_service }}</span
                        >
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col></b-row
        >
      </section>
    </WrapperDrawer>
  </section>
</template>

<script>
import InputSearchComponent from "@/modules/dashboard/components/InputSearch";
import SelectOrderByComponent from "@/modules/dashboard/components/SelectOrderBy";
import FilterBetweenDatesSelect from "@/modules/dashboard/components/FilterBetweenDatesSelect";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import EmptyData from "@/components/General/EmptyData";
import FormatAmount from "@/components/General/FormatAmount";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import ButtonText from "@/components/Buttons/ButtonText";

import businessService from "@/modules/dashboard/profile/pages/business/services/index";

export default {
  name: "EarningList",
  data() {
    return {
      toggleApiRequest: false,
      earnings: [],
      searchFilters: {},
      searchQuery: null,
      orderByQuery: null,
      perPaginate: 10,
      paginate: ["resultQueryEarnings"],
      earningView: null,
      drawerEarningView: false,
      showCommissionBank: false
    };
  },
  computed: {
    resultQueryEarnings() {
      let earnings = this.earnings;
      return earnings
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.description.toLowerCase() < b.description.toLowerCase()
                ? -1
                : 1;
            case "z-a":
              return a.description.toLowerCase() > b.description.toLowerCase()
                ? -1
                : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.description
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            : item
        );
    },
    totalEarnings() {
      let amount = 0;
      this.earnings.forEach(e => (amount += e.net_income));
      return amount;
    },
    requestFilters() {
      const { from = null, to = null } = this.searchFilters;
      return { from, to };
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onAsyncDataForDates({ searchDates }) {
      this.updatedSearchFilters(searchDates);
      this.earnings = [];
      this.getBusinessEarnings();
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };
    },
    onCloseDrawerEarningView() {
      this.drawerEarningView = false;
    },
    onClickEarningView(earning) {
      this.earningView = earning;
      this.drawerEarningView = true;
    },
    onViewCommissionBank() {
      this.showCommissionBank = !this.showCommissionBank;
    },
    async getBusinessEarnings() {
      try {
        this.toggleApiRequest = true;
        let response = await businessService
          .getBusinessEarnings({
            params: {
              ...this.searchFilters
            }
          })
          .finally(() => {
            this.toggleApiRequest = false;
          });
        const { data, success } = response.data;
        if (success) {
          this.earnings = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    EmptyData,
    FormatAmount,
    FilterBetweenDatesSelect,
    WrapperDrawer,
    ButtonText
  },
  props: {
    setEarnings: {
      default: null
    },
    showTotalEarnings: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    if (!this.setEarnings) {
      this.getBusinessEarnings();
    } else {
      this.earnings = this.setEarnings;
    }
  }
};
</script>

<style lang="scss" scoped></style>
